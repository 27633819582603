
import React, {useEffect} from 'react';

function App() {
  useEffect(() => {
    window.location.assign('https://forms.alariscloud.com/231935823194966')
  }, []);
  return (
    <div />
  );
}

export default App;
